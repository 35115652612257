<template>
    <div v-if="user">
        <div class="tit-wrap tit-wrap--lg text-center">
            <h3 class="tit font-weight-light">
                인증이 완료되었습니다.<br />
                고객님의 ID는 다음과 같습니다.
            </h3>
        </div>
        <v-row>
            <v-col cols="6" class="text-center">
                <div class="tit-wrap tit-wrap--sm txt txt-sm">가입한 ID</div>
                <div class="tit font-weight-light">{{ user.username }}</div>
            </v-col>
            <v-col cols="6" class="text-center">
                <div class="tit-wrap tit-wrap--sm txt txt-sm">회원 가입일</div>
                <div class="tit font-weight-light">{{ $dayjs(user.createdAt).format("YYYY.MM.DD")}}</div>
            </v-col>
        </v-row>
        <div class="btn-wrap btn-wrap--lg">
            <v-row class="row--xxs">
                <v-col cols="12" lg="6">
                    <v-btn x-large block v-bind="{ ...btn_primary }" href="/login">로그인</v-btn>
                </v-col>
                <v-col cols="12" lg="6">
                    <v-btn x-large block v-bind="{ ...btn_secondary }" href="/login/find-password">비밀번호 재설정</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_secondary } from "@/assets/variables";

export default {
    components: {},
    async mounted(){
        await this.init();
    },
    data() {
        return {
            btn_primary,
            btn_secondary,
            
            user: null
        };
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            try{
                let { user } = await api.v1.users.get({_id: this.$route.params._user});

                this.user = user;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    }
};
</script>
