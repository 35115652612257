<template>
    <div class="d-flex align-center">
        <div class="w-md-270px">
            <v-row align="center" :class="{ 'ma-n4px': true, hasCertification }">
                <v-col class="pa-4px">
                    <v-text-field v-model="phone1" v-bind="{ ...$attrs, label, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
                </v-col>
                <v-col cols="auto" class="pa-4px">-</v-col>
                <v-col class="pa-4px">
                    <v-text-field v-model="phone2" v-bind="{ ...$attrs, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
                </v-col>
                <v-col cols="auto" class="pa-4px">-</v-col>
                <v-col class="pa-4px">
                    <v-text-field v-model="phone3" v-bind="{ ...$attrs, readonly, dense }" hide-details="auto" type="number" v-on="{ input, click }" />
                </v-col>
                <template v-if="rules">
                    <v-col cols="12" class="pa-4px">
                        <v-text-field :value="phone" v-bind="{ rules }" hide-details="auto" class="v-phone-field-rules pa-0 ma-0" />
                    </v-col>
                </template>
            </v-row>
        </div>
        <div v-if="hasCertification" class="pl-8px">
            <v-row align="center" :class="{ 'ma-n4px': true, hasCertification }">
                <v-col cols="12" sm="auto" class="pa-4px">
                    <v-btn v-bind="{ ...btn_secondary2, ...btnAttrs, ...$attrs, dense }" :small="dense == true ? true : false" :x-large="dense == false ? true : false" class="w-100 w-sm-80px" @click="certify">본인인증</v-btn>
                </v-col>
                <kcp-cert ref="kcp-cert" @input="processCertifiedData" />
            </v-row>
        </div>
    </div>
</template>

<script>
import { btn_secondary2 } from "@/assets/variables";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";

export default {
    components: {
        KcpCert,
    },
    props: {
        value: { type: String }, // phone
        label: { type: String },
        btnAttrs: { type: Object, default: () => ({}) }, // user
        dense: { type: Boolean },
        rules: { type: Array, default: null },
        hasCertification: { type: Boolean, default: false },
    },
    data: () => ({
        btn_secondary2,

        phone1: "010",
        phone2: null,
        phone3: null,
        _certification: null,
    }),
    computed: {
        readonly() {
            return this.hasCertification;
        },
        phone() {
            const { phone1, phone2, phone3 } = this;
            if (!!phone1 && !!phone2 && !!phone3) return `${phone1}-${phone2}-${phone3}`;
            else return null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.phone != this.value) this.sync();
        },
        phone() {
            this.emit();
        },
        _certification() {
            this.emit();
        },
    },
    methods: {
        sync() {
            [this.phone1, this.phone2, this.phone3] = this.value?.split?.("-") || ["010", null, null];
        },
        emit() {
            this.$emit("input", this.phone);
            this.$emit("certification", this._certification);
        },
        input() {
            this.$nextTick(() => {
                this.phone1 = this.phone1?.slice?.(0, 4) || null;
                this.phone2 = this.phone2?.slice?.(0, 4) || null;
                this.phone3 = this.phone3?.slice?.(0, 4) || null;
            });
        },
        click() {
            if (this.hasCertification) this.certify();
        },
        certify() {
            this.$refs["kcp-cert"]?.auth?.();
        },
        processCertifiedData(payload) {
            [this.phone1, this.phone2, this.phone3] = payload?.phone?.phoneNumberFormat()?.split?.("-");
            this._certification = payload._certification;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    input {
        text-align: center;
    }

    .v-phone-field-rules {
        .v-input__slot {
            display: none;
        }
    }
}
.hasCertification ::v-deep input {
    cursor: pointer !important;
}
</style>
