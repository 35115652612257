<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <template slot="tit">로그인</template>

            <v-card tile max-width="416" class="mx-auto">
                <v-row class="row--xs" align="center">
                    <v-col cols="3" class="d-none d-lg-block font-weight-bold">아이디</v-col>
                    <v-col>
                        <v-text-field v-model="username" v-bind="{ ...attrs_input_secondary }" dense type="email" placeholder="아이디를 입력해주세요." />
                    </v-col>
                </v-row>
                <v-row class="row--xs" align="center">
                    <v-col cols="3" class="d-none d-lg-block font-weight-bold">비밀번호</v-col>
                    <v-col>
                        <v-text-field v-model="password" v-bind="{ ...attrs_input_secondary }" dense type="password" name="" value="" placeholder="비밀번호를 입력해주세요." @keydown.enter="login" />
                    </v-col>
                </v-row>
                <v-row class="row--sm">
                    <v-col cols="3" class="d-none d-lg-block"></v-col>
                    <v-col>
                        <v-checkbox v-model="remember" v-bind="{ ...checkbox_primary }" label="아이디저장" :value="true" />
                    </v-col>
                </v-row>

                <div class="btn-wrap">
                    <v-row class="row--sm">
                        <v-col cols="12">
                            <v-btn v-bind="{ ...btn_secondary }" block @click="login" x-large>로그인</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <div class="divider-group text-center">
                                <ul class="grey--text">
                                    <li>
                                        <router-link to="/login/find-id/">아이디 찾기</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/login/find-password/">비밀번호 재설정</router-link>
                                    </li>
                                    <!-- <li>
                                        <router-link to="/join">회원가입</router-link>
                                    </li> -->
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div class="btn-wrap btn-wrap--md">
                    <!-- <div class="v-divider--center">
                        <span class="v-divider__txt" style="white-space: nowrap">SNS로 간편 로그인하기</span>
                        <v-divider />
                    </div> -->
                    <v-row class="row--xxs">
                        <v-col cols="12" lg="6">
                            <kakao-login client_id="21f7a966f13bf90dc3f3c6e0401f4445">카카오 로그인</kakao-login>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <naver-login client_id="U5mxgu3NbIYiYj7likp6">네이버 로그인</naver-login>
                        </v-col>
                        <!-- <v-col cols="12">
                                <naver-login client_id="3F0hLX01jgfYpTLzzxO6">네이버로 계속하기</naver-login>
                            </v-col> -->
                        <!-- <v-col cols="12">
                                <v-btn block color="#1877F2" class="v-size--xx-large"> <i class="icon icon-facebook mr-auto" /><span class="mr-auto white--text">페이스북으로 계속하기</span> </v-btn>
                            </v-col> -->
                    </v-row>
                </div>
                <div class="btn-wrap btn-wrap--md">
                    <v-row class="row--xxs" justify="space-between">
                        <v-col cols="auto">아직 회원이 아니신가요?</v-col>
                        <!-- <v-col cols="auto"><router-link to="/" class="grey--text txt txt--xs">비회원 주문조회</router-link></v-col> -->
                    </v-row>
                    <v-row class="row--xxs">
                        <v-col>
                            <v-btn v-bind="{ ...btn_primary }" block to="/join" x-large>회원가입</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </page-section>
    </client-page>
</template>

<script>
import { attrs_input_secondary, btn_primary, btn_secondary, checkbox_primary } from "@/assets/variables";

import CryptoAES from "../../../plugins/crypto-aes";
import KakaoLogin from "../../../components/plugins/kakao/kakao-login.vue";
import NaverLogin from "../../../components/plugins/naver/naver-login.vue";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        KakaoLogin,
        NaverLogin,
        ClientPage,
        PageSection,
    },
    data: () => {
        return {
            attrs_input_secondary,
            btn_primary,
            btn_secondary,
            checkbox_primary,

            username: "",
            password: "",

            remember: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (this.$cookies.get("username")) this.username = this.$cookies.get("username");
            if (this.$cookies.get("password")) this.password = CryptoAES.decrypt(this.$cookies.get("password"));
        },
        async login() {
            try {
                await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

                if (this.remember) {
                    this.$cookies.set("username", this.username);
                    this.$cookies.set("password", CryptoAES.encrypt(this.password));
                }

                this.$router.go(-1);
                this.$nextTick(() => this.$router.replace("/"));
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>
