var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h3', {
    staticClass: "tit font-weight-light"
  }, [_vm._v(" 인증이 완료되었습니다."), _c('br'), _vm._v(" 비밀번호를 재설정 해주세요. ")])]), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm txt txt-sm"
  }, [_vm._v("가입한 ID")]), _c('div', {
    staticClass: "tit font-weight-light"
  }, [_vm._v(_vm._s(_vm.user.username))])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm txt txt-sm"
  }, [_vm._v("비밀번호 재설정")]), _c('v-text-field', _vm._b({
    attrs: {
      "rules": _vm.rules.password,
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm txt txt-sm"
  }, [_vm._v("비밀번호 확인")]), _c('v-text-field', _vm._b({
    attrs: {
      "max-width": "400",
      "type": "password"
    },
    model: {
      value: _vm.passwordConfirm,
      callback: function ($$v) {
        _vm.passwordConfirm = $$v;
      },
      expression: "passwordConfirm"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input), false))], 1)], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "disabled": !_vm.isValid,
      "x-large": "",
      "min-width": "416"
    },
    on: {
      "click": _vm.recovery
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }