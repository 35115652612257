var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "g-recaptcha",
    attrs: {
      "id": "g-recaptcha",
      "data-sitekey": _vm.rcapt_key
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }