<template>
    <div>
        <div class="tit-wrap text-center">
            <h2 class="tit">비밀번호 재설정</h2>
            <p class="txt txt--sm txt--dark">
                가입한 아이디를 입력해주세요.<br />
                휴대폰 본인인증을 통해 비밀번호를 변경합니다.
            </p>
        </div>

        <v-card tile max-width="416" class="mx-auto">
            <v-row class="row--xxs">
                <v-col cols="12">
                    <v-text-field v-model="username" v-bind="{ ...attrs_input }" placeholder="아이디를 입력해주세요." />
                </v-col>
                <v-col cols="12">
                    <v-btn v-bind="{ ...btn_secondary }" block x-large @click="certify">휴대폰 인증</v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-divider class="my-20px my-lg-40px" />

        <div class="tit-wrap text-center">
            <h2 class="tit tit--xxs">등록된 정보로 비밀번호 재설정</h2>
            <p class="txt txt--sm txt--dark">회원가입 시 등록한 정보로 <br class="d-lg-none" />비밀번호를 재설정 할 수 있습니다.</p>
        </div>
        <v-card tile max-width="416" class="mx-auto">
            <v-row class="row--xs" align="center">
                <v-col cols="3" class="d-none d-lg-block font-weight-bold">이름</v-col>
                <v-col>
                    <v-text-field v-model="form.name" v-bind="{ ...attrs_input_secondary }" dense placeholder="이름을 입력해주세요." />
                </v-col>
            </v-row>
            <v-row class="row--xs" align="center">
                <v-col cols="3" class="d-none d-lg-block font-weight-bold">아이디</v-col>
                <v-col>
                    <v-text-field v-model="form.username" v-bind="{ ...attrs_input_secondary }" dense placeholder="아이디를 입력해주세요." />
                </v-col>
            </v-row>
            <v-row class="row--xs" align="center">
                <v-col cols="3" class="d-none d-lg-block font-weight-bold">이메일</v-col>
                <v-col>
                    <v-text-field v-model="form.email" v-bind="{ ...attrs_input_secondary }" dense type="email" placeholder="이메일을 입력해주세요." />
                </v-col>
            </v-row>
            <v-row class="row--xs" align="center">
                <v-col cols="3" class="d-none d-lg-block font-weight-bold">보안문자</v-col>
                <v-col cols="12" lg="9">
                    <!-- 보안문자 들어가는 곳 -->
                    <re-captcha ref="reCaptcha" @input="recaptcha"/>
                </v-col>
            </v-row>

            <div class="btn-wrap btn-wrap--md">
                <v-row class="row--xxs">
                    <v-col cols="12">
                        <v-btn v-bind="{ ...btn_secondary }" block x-large @click="find">확인</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn v-bind="{ ...btn_primary }" block x-large to="/join">회원가입</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card>

        <find-id-dialog :showDialog="showDialog"  @dialog="(dialog)=>showDialog=dialog"/>
        <kcp-cert ref="kcpCert" @input="verify"/>
    </div>
</template>

<script>
import api from "@/api";
import cryptoAes from '@/plugins/crypto-aes';

import ReCaptcha from "@/components/plugins/recaptcha/re-captcha.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import FindIdDialog from "@/components/client/login/find-id-dialog.vue";
import { attrs_input, attrs_input_secondary, btn_primary, btn_secondary } from "@/assets/variables";

export default {
    components: {
        ReCaptcha,
        KcpCert,
        FindIdDialog
    },
    data() {
        return {
            attrs_input,
            attrs_input_secondary,
            btn_primary,
            btn_secondary,
            showDialog: false,
            captcha: false,

            // 등록된 정보
            form:{
                name: "",
                username: "",
                email: ""
            },

            // 본인인증
            username: ""
        };
    },
    methods:{
        certify(){
            if(!this.username) {
                alert("아이디를 입력해주세요");
                return;
            }
            this.$refs.kcpCert.auth();
        },

        async verify(payload){
            try{
                const { ci } = payload;

                const { _user } = await api.v1.users.existence({ ci: cryptoAes.encrypt(ci.replace(/[ ]/g, "+")), username: this.username });

                if(!_user) {
                    this.showDialog = true;
                    return;
                }
                
                this.$router.push(`/login/find-password/${_user}`);

            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async find(){
            try{
                if(!this.form.name) throw new Error("이름을 입력해주세요");
                if(!this.form.username) throw new Error("아이디를 입력해주세요");
                if(!this.form.email) throw new Error("이메일를 입력해주세요");
                await this.$refs.reCaptcha.captcha();
                if(!this.captcha) throw new Error("보안문자를 확인해주세요");

                const { _user } = await api.v1.users.existence({...this.form});
                
                if(!_user) {
                    this.showDialog = true;
                    return;
                }
                
                this.$router.push(`/login/find-password/${_user}`);
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        recaptcha(success){
            this.captcha = success;
        }
    }
};
</script>
