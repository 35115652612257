var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("로그인")]), _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "tile": "",
      "max-width": "416"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("아이디")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "dense": "",
      "type": "email",
      "placeholder": "아이디를 입력해주세요."
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_secondary), false))], 1)], 1), _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("비밀번호")]), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "dense": "",
      "type": "password",
      "name": "",
      "value": "",
      "placeholder": "비밀번호를 입력해주세요."
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_secondary), false))], 1)], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "cols": "3"
    }
  }), _c('v-col', [_c('v-checkbox', _vm._b({
    attrs: {
      "label": "아이디저장",
      "value": true
    },
    model: {
      value: _vm.remember,
      callback: function ($$v) {
        _vm.remember = $$v;
      },
      expression: "remember"
    }
  }, 'v-checkbox', Object.assign({}, _vm.checkbox_primary), false))], 1)], 1), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "x-large": ""
    },
    on: {
      "click": _vm.login
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("로그인")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider-group text-center"
  }, [_c('ul', {
    staticClass: "grey--text"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/login/find-id/"
    }
  }, [_vm._v("아이디 찾기")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/login/find-password/"
    }
  }, [_vm._v("비밀번호 재설정")])], 1)])])])], 1)], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--md"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('kakao-login', {
    attrs: {
      "client_id": "21f7a966f13bf90dc3f3c6e0401f4445"
    }
  }, [_vm._v("카카오 로그인")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('naver-login', {
    attrs: {
      "client_id": "U5mxgu3NbIYiYj7likp6"
    }
  }, [_vm._v("네이버 로그인")])], 1)], 1)], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--md"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("아직 회원이 아니신가요?")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "to": "/join",
      "x-large": ""
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("회원가입")])], 1)], 1)], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }