var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    attrs: {
      "disabled": _vm.disabled,
      "id": 'kakao-login-btn',
      "href": `https://kauth.kakao.com/oauth/authorize?client_id=${_vm.client_id}&redirect_uri=${_vm.redirect_uri}&response_type=code`,
      "block": "",
      "color": "#FFEB00",
      "x-large": "",
      "tile": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-kakao mr-8px"
  }), _c('span', [_vm._t("default", null, {
    "default": ""
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }