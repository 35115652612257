var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "474"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.localDialog,
      callback: function ($$v) {
        _vm.localDialog = $$v;
      },
      expression: "localDialog"
    }
  }, [_c('v-card', {
    staticClass: "text-center",
    attrs: {
      "tile": "",
      "color": "#fff"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "icon": "",
      "tile": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function () {
        return _vm.localDialog = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-title', {
    staticClass: "tit-wrap tit-wrap--xs justify-center pa-0"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("알림")])]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('p', {
    staticClass: "txt"
  }, [_vm._v(" 일치하는 정보가 없습니다."), _c('br'), _vm._v(" 아이디 찾기 방법을 변경하거나 회원가입 해주세요. ")])]), _c('v-card-actions', {
    staticClass: "pt-20px pt-lg-40px px-0 pb-0"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": ""
    },
    on: {
      "click": function () {
        return _vm.localDialog = false;
      }
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }