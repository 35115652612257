<template>
    <div id="g-recaptcha" class="g-recaptcha" :data-sitekey="rcapt_key"></div>
</template>

<script>
export default {
    data:()=>({
        rcapt_key: process.env.VUE_APP_RECAPTCHA_KEY,
        rcapt_id: 0,
    }),
    methods: {
        init(){
            if (window.grecaptcha) {
                this.rcapt_id = grecaptcha.render( $('.g-recaptcha')[0], { sitekey : this.rcapt_key });
            };
        },
        async captcha(){
            try{
                let token = grecaptcha.getResponse(this.rcapt_id);
                
                this.$emit("input", token);
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    mounted(){
        this.init();
    }
}
</script>

<style>

</style>