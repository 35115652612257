<template>
    <div v-if="user">
        <v-form v-model="isValid">
            <div class="tit-wrap tit-wrap--lg text-center">
                <h3 class="tit font-weight-light">
                    인증이 완료되었습니다.<br />
                    비밀번호를 재설정 해주세요.
                </h3>
            </div>
            <v-row justify="center">
                <v-col class="text-center">
                    <div class="tit-wrap tit-wrap--sm txt txt-sm">가입한 ID</div>
                    <div class="tit font-weight-light">{{  user.username }}</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="center">
                    <div class="tit-wrap tit-wrap--sm txt txt-sm">비밀번호 재설정</div>
                    <v-text-field v-model="password" :rules="rules.password" v-bind="{ ...attrs_input }" type="password" />
                </v-col>
                <v-col cols="auto"><v-divider vertical /></v-col>
                <v-col align="center">
                    <div class="tit-wrap tit-wrap--sm txt txt-sm">비밀번호 확인</div>
                    <v-text-field v-model="passwordConfirm" max-width="400" v-bind="{ ...attrs_input }" type="password" />
                </v-col>
            </v-row>
            <div class="btn-wrap btn-wrap--lg">
                <v-row justify="center">
                    <v-col align="center">
                        <v-btn :disabled="!isValid" x-large min-width="416" v-bind="{ ...btn_primary }" @click="recovery">확인</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-form>
    </div>
</template>

<script>
import api from "@/api";
import cryptoAes from '@/plugins/crypto-aes';
import { attrs_input, btn_primary, btn_secondary, rules } from "@/assets/variables";

export default {
    components: {},
    async mounted(){
        await this.init();
    },
    data() {
        return {
            rules,
            attrs_input,
            btn_primary,
            btn_secondary,
            isValid: true,

            user: null,
            password: "",
            passwordConfirm: ""
        };
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            try{
                let { user } = await api.v1.users.get({_id: this.$route.params._user});

                this.user = user;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async recovery(){
            try{
                if(!this.validates()) return;

                await api.v1.users.recovery({ _id: this.$route.params._user, password: cryptoAes.encrypt(this.password) }); 

                alert("비밀번호가 변경되었습니다.");

                this.$router.push("/login");
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        validates(){
            if(!this.password) throw new Error("비밀번호를 입력해주세요");
            if(this.password !== this.passwordConfirm) throw new Error("비밀번호가 일치하지 않습니다");
            return true;
        }
    }

};
</script>
