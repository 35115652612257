<template>
    <v-tab v-bind="$attrs">
        <slot />
    </v-tab>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.v-tab {
    &::after {
        left: 0;
        transform: translate(0);
        width: calc(100% + 1px);
        height: 100%;
        background-color: initial;
        border: 1px solid var(--border-color);
        border-bottom-color: var(--v-primary-base);
    }
    &--active {
        &::after {
            border-color: var(--v-primary-base);
            border-bottom: none;
            z-index: 1;
        }
    }
    &:last-child {
        &::after {
            width: 100%;
        }
    }
}
</style>
