<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <v-tabs grow class="mb-40px mb-lg-60px">
                <tab-primary :to="{path:'/login/find-id'}">아이디 찾기</tab-primary>
                <tab-primary :to="{path:'/login/find-password'}">비밀번호 재설정</tab-primary>
            </v-tabs>

            <find-password v-if="!this.$route.params._user"/>
            <find-password-done v-else />
        </page-section>
    </client-page>
</template>

<script>
// import CryptoAES from "@/plugins/crypto-aes";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
// import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";
import FindPassword from '@/components/client/login/find-password.vue';
import FindPasswordDone from '@/components/client/login/find-password-done.vue';

export default {
    components: {
        ClientPage,
        PageSection,
        // VPhoneField,
        TabPrimary,
        FindPassword,
        FindPasswordDone,
    },
};
</script>
