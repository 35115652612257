<template>
    <v-btn :disabled="disabled" v-bind:id="'kakao-login-btn'" v-bind:href="`https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`" block color="#FFEB00" x-large tile>
        <i class="icon icon-kakao mr-8px" /><span><slot default></slot></span>
    </v-btn>
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
    },
    computed: {
        redirect_uri: function () {
            return `${document.location.protocol}//${document.location.host}/oauth/kakao`;
        },
    },
};
</script>
