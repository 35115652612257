<template>
    <span></span>
</template>
<script>
export default {
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { accessToken, login } = this.$route.query;
                if (!accessToken) throw new Error("SNS 로그인에 실패했습니다");
                if (login == "true") {
                    // 메인화면으로 이동
                    await this.$store.dispatch("login", { accessToken });
                    this.$router.push({ path: "/" });
                } else {
                    // 회원가입화면으로 이동
                    this.$router.push({ path: "/join/certify", query: this.$route.query });
                }
            } catch (error) {
                alert(error.message);
                this.$router.push({ path: "/login" });
            }
        },
    },
};
</script>
