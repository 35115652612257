var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', [_vm._m(0), _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm txt txt-sm"
  }, [_vm._v("가입한 ID")]), _c('div', {
    staticClass: "tit font-weight-light"
  }, [_vm._v(_vm._s(_vm.user.username))])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm txt txt-sm"
  }, [_vm._v("회원 가입일")]), _c('div', {
    staticClass: "tit font-weight-light"
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.user.createdAt).format("YYYY.MM.DD")))])])], 1), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "block": "",
      "href": "/login"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("로그인")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "block": "",
      "href": "/login/find-password"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("비밀번호 재설정")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('h3', {
    staticClass: "tit font-weight-light"
  }, [_vm._v(" 인증이 완료되었습니다."), _c('br'), _vm._v(" 고객님의 ID는 다음과 같습니다. ")])]);

}]

export { render, staticRenderFns }