<template>
    <v-dialog v-model="localDialog" persistent width="474">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-card tile color="#fff" class="text-center">
            <v-row no-gutters justify="end">
                <v-btn large icon tile text color="primary" @click="() => (localDialog = false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-card-title class="tit-wrap tit-wrap--xs justify-center pa-0">
                <h3 class="tit tit--xs">알림</h3>
            </v-card-title>
            <v-card-text class="pa-0">
                <p class="txt">
                    일치하는 정보가 없습니다.<br />
                    아이디 찾기 방법을 변경하거나 회원가입 해주세요.
                </p>
            </v-card-text>
            <v-card-actions class="pt-20px pt-lg-40px px-0 pb-0">
                <v-btn v-bind="{ ...btn_primary }" block @click="() => (localDialog = false)">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { btn_primary } from "@/assets/variables";
import UDialog from "@/components/client/dumb/u-dialog.vue";

export default {
    props: {
        showDialog: { type: Boolean, default: false },
    },
    components: {
        UDialog,
    },
    data: () => ({
        btn_primary,
        localDialog: false,
    }),
    watch: {
        showDialog(newVal) {
            this.localDialog = newVal;
        },
        localDialog(newVal) {
            this.$emit("dialog", newVal);
        },
    },
};
</script>
