var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('v-tabs', {
    staticClass: "mb-40px mb-lg-60px",
    attrs: {
      "grow": ""
    }
  }, [_c('tab-primary', {
    attrs: {
      "to": {
        path: '/login/find-id'
      }
    }
  }, [_vm._v("아이디 찾기")]), _c('tab-primary', {
    attrs: {
      "to": {
        path: '/login/find-password'
      }
    }
  }, [_vm._v("비밀번호 재설정")])], 1), !this.$route.params._user ? _c('find-id') : _c('find-id-done')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }