<template>
    <div>
        <div class="tit-wrap text-center">
            <h2 class="tit">아이디 찾기</h2>
            <p class="txt txt--sm txt--dark">
                휴대폰 인증을 해주세요.<br />
                인증에 성공하면 아이디를 확인할 수 있습니다.
            </p>
            <v-row no-gutters justify="center">
                <v-col cols="auto">
                    <v-radio-group v-model="type" row hide-details="auto" class="mt-16px mt-lg-20px">
                        <v-radio v-bind="{ ...radio_primary }" label="개인회원" :value="USER_TYPES.PERSON.value" />
                        <v-radio v-bind="{ ...radio_primary }" label="입점사회원" :value="USER_TYPES.COMPANY.value" />
                    </v-radio-group>
                </v-col>
            </v-row>
        </div>

        <v-card tile max-width="416" class="mx-auto">
            <v-btn v-bind="{ ...btn_secondary }" block x-large @click="certify">휴대폰 인증</v-btn>
        </v-card>

        <v-divider class="my-20px my-lg-40px" />

        <div class="tit-wrap text-center">
            <h2 class="tit tit--xxs">등록된 정보로 아이디 찾기</h2>
            <p class="txt txt--sm txt--dark">회원가입 시 등록한 정보로 <br class="d-lg-none" />아이디를 찾을 수 있습니다.</p>
            <v-row no-gutters justify="center">
                <v-col cols="auto">
                    <v-radio-group v-model="form.type" row hide-details="auto" class="mt-16px mt-lg-20px">
                        <v-radio v-bind="{ ...radio_primary }" label="개인회원" :value="USER_TYPES.PERSON.value" />
                        <v-radio v-bind="{ ...radio_primary }" label="입점사회원" :value="USER_TYPES.COMPANY.value" />
                    </v-radio-group>
                </v-col>
            </v-row>
        </div>
        <v-card tile max-width="416" class="mx-auto">
            <v-row class="row--xs" align="center">
                <v-col cols="3" class="d-none d-lg-block font-weight-bold">이름</v-col>
                <v-col>
                    <v-text-field v-model="form.name" v-bind="{ ...attrs_input_secondary }" dense placeholder="이름을 입력해주세요." />
                </v-col>
            </v-row>
            <v-row class="row--xs" align="center">
                <v-col cols="3" class="d-none d-lg-block font-weight-bold">이메일</v-col>
                <v-col>
                    <v-text-field v-model="form.email" v-bind="{ ...attrs_input_secondary }" dense type="email" placeholder="이메일을 입력해주세요." />
                </v-col>
            </v-row>
            <v-row class="row--xs" align="center">
                <v-col cols="3" class="d-none d-lg-block font-weight-bold">보안문자</v-col>
                <v-col cols="12" lg="9">
                    <!-- 보안문자 들어가는 곳 -->
                    <re-captcha ref="reCaptcha" @input="recaptcha" />
                </v-col>
                <!-- <v-col><v-spacer /></v-col>
                <v-col cols="12" lg="9">
                    <v-text-field v-bind="{ ...attrs_input_secondary }" dense placeholder="보안문자를 입력해주세요." />
                </v-col> -->
            </v-row>

            <div class="btn-wrap btn-wrap--md">
                <v-row class="row--xxs">
                    <v-col cols="12">
                        <v-btn v-bind="{ ...btn_secondary }" block x-large @click="find">확인</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn v-bind="{ ...btn_primary }" block x-large to="/join">회원가입</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <find-id-dialog :showDialog="showDialog" @dialog="(dialog) => (showDialog = dialog)" />
        <kcp-cert ref="kcpCert" @input="verify" />
    </div>
</template>

<script>
import api from "@/api";
import cryptoAes from "@/plugins/crypto-aes";

import ReCaptcha from "@/components/plugins/recaptcha/re-captcha.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import FindIdDialog from "@/components/client/login/find-id-dialog.vue";
import { attrs_input_secondary, btn_primary, btn_secondary, radio_primary, USER_TYPES } from "@/assets/variables";

export default {
    components: {
        KcpCert,
        FindIdDialog,
        ReCaptcha,
    },
    data() {
        return {
            USER_TYPES,
            showDialog: false,

            type: USER_TYPES.PERSON.value,

            
            form: {
                type: USER_TYPES.PERSON.value,
                name: "",
                email: "",
                token: null,
            },

            attrs_input_secondary,
            btn_primary,
            btn_secondary,
            radio_primary,
        };
    },
    methods: {
        validate() {
            try {
                if (!this.form.type) throw new Error("회원구분을 선택해주세요");
                if (!this.form.name) throw new Error("이름을 입력해주세요");
                if (!this.form.email) throw new Error("이메일를 입력해주세요");
                if (!this.form.token) throw new Error("보안문자를 확인해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async find() {
            try {
                await this.$refs.reCaptcha.captcha();
                if (this.validate()) {
                    let { _user } = await api.v1.users.existence({ ...this.form });

                    if (!_user) {
                        this.showDialog = true;
                        return;
                    }

                    this.$router.push(`/login/find-id/${_user}`);
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        certify() {
            this.$refs.kcpCert.auth();
        },

        async verify(payload) {
            try {
                let { ci } = payload;
                const { _user } = await api.v1.users.existence({ ci: cryptoAes.encrypt(ci.replace(/[ ]/g, "+")), type: this.type });

                if (!_user) {
                    this.showDialog = true;
                    return;
                }

                this.$router.push(`/login/find-id/${_user}`);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        recaptcha(token) {
            this.form.token = token;
        },
    },
};
</script>

<style lang="scss" scoped>
::deep() {
    .v-input--radio-group__input {
        justify-content: center;
    }
}
</style>
