<template>
    <v-btn :disabled="disabled" v-bind:id="'naver-login-button'" v-bind:href="`https://nid.naver.com/oauth2.0/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}`" block color="#00C73C" x-large tile>
        <i class="icon icon-naver mr-8px" /><span class="white--text"><slot default></slot></span>
    </v-btn>
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
    },
    computed: {
        redirect_uri() {
            return `${document.location.protocol}//${document.location.host}/oauth/naver`;
        },
    },
};
</script>
